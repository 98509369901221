<template>
  <div class="week__bar">
    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: "WeekBar",
  components: {
    apexchart: VueApexCharts
  },
  data() {
    return {
      weeks: [],
      series: [{
        name: '',
        data: []
      }],
      chartOptions: {
        chart: {
          height: 300,
          type: 'bar',
        },
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },

        xaxis: {
          categories: [],
          position: 'top',
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val
            }
          }

        },
        title: {
          text: '',
          floating: true,
          offsetY: 330,
          align: 'center',
          style: {
            color: '#444'
          }
        }
      },
    }
  },
  methods: {
    async getWeekAmount() {
      try {
        const response = await this.$axios.get(`${this.$API_URL}bonus/week`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        });

        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            ...this.chartOptions.xaxis,
            categories: this.weeks.categories
          }
        };

        this.chartOptions = {
          chart: {
            height: 300,
                type: 'bar',
          },
          plotOptions: {
            bar: {
              borderRadius: 10,
                  dataLabels: {
                position: 'top', // top, center, bottom
              },
            }
          },
          dataLabels: {
            enabled: true,
                formatter: function (val) {
              return val
            },
            offsetY: -20,
                style: {
              fontSize: '12px',
                  colors: ["#304758"]
            }
          },

          xaxis: {
            categories: response.data['days'],
                position: 'top',
                axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: 'gradient',
                    gradient: {
                  colorFrom: '#D8E3F0',
                      colorTo: '#BED1E6',
                      stops: [0, 100],
                      opacityFrom: 0.4,
                      opacityTo: 0.5,
                }
              }
            },
            tooltip: {
              enabled: true,
            }
          },
          yaxis: {
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false,
            },
            labels: {
              // show: false,
              //     formatter: function () {
              //   return ''
              // }
            }

          },
          title: {
            text: '',
                floating: true,
                offsetY: 330,
                align: 'center',
                style: {
              color: '#444'
            }
          }
        }
        this.weeks = response.data;
        this.series = [{
          data: response.data['weeks']
        }]

        // this.chartOptions.xaxis.categories = response.data['days']

      } catch (error) {
        console.error("Error fetching today's amount:", error);
      }
    },
  },
  mounted() {
    this.getWeekAmount()
  },
  watch: {},
};
</script>

<style lang="scss" scoped>

</style>